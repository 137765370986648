<template>
  <div class="search-in">
    <div class="close-button" @click="onCancele">
      <i class="el-icon-circle-close"></i>
    </div>
    <div class="search-in-title">港口船舶实时查询分析</div>
    <el-form ref="form" :model="formUpdateData">
      <el-form-item style="margin-left: -10px" label="港口选择" class="form-item" required>
        <el-select
          v-model="selectPort"
          style="width: 250px"
          filterable
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="item in portList"
            :key="item.id"
            :label="'英文名：' + item.name + ' 中文名：' + item.cnName"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item size="large" style="text-align: center; margin-top: 30px">
        <el-button id="btn-confirm" type="primary" @click="onSubmit" style="width: 100px"
          >查询</el-button
        >
        <el-button id="btn-cancel" style="margin-left: 30px; width: 100px" @click="onCancele"
          >取消</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "portSelector",
  props: ['portList'],
  data() {
    return {
      formUpdateData: null,
      selectPort: ""
    };
  },
  mounted() {},
  methods: {
    onSubmit() {
      console.log(
        "submit: " +
          " selectPort: " + this.selectPort
      );

      if (this.selectPort == null ||this.selectPort === "" || this.selectPort === " ") {
        this.$message({
          message: "港口不能为空！",
          type: "error",
          duration: 1600,
        });
        return;
      }

      let item;
      this.portList.forEach(element => {
        if(element.name == this.selectPort){
          item = element;
        }
        
      });
      console.log(
        item
      );
      
      this.$emit("newPortInit", item)


    },
    onCancele() {
      this.$emit("onPortSelectorCancel")
    },
    selectChange(value){
      this.selectPort = value;
    }
  },
};
</script>

<style scoped lang="less">
.search-in {
  width: 340px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: -5px 0 3px -1px gray, 0 -2px 3px -1px gray, 0 2px 3px -1px gray, 2px 0 3px -1px gray;

  color: white;
  position: absolute;
  top: 0;
  left: 0;
  padding: 30px 30px;
  padding-top: 40px;

  #btn-confirm {
    background-color: #165184;
    border-color: #165184;
  }

  #btn-cancel {
    background-color: #3c3d3f;
    border-color: #3c3d3f;
    color: white;
  }

  .search-in-title {
    text-align: center;
    font-size: 15pt;
    //color: white;
    margin-bottom: 20px;
    margin-top: 5px;
  }

  /deep/ .el-form-item__label {
    font-size: 12pt;
    color: #7cd8da;
  }

  .form-item {
    margin-top: 30px;
  }

  .close-button {
    position: absolute;
    top: 3px;
    right: 5px;
    z-index: 9277;
    font-size: 25px;
    color: #fff;
 }

}
</style>
